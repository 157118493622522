import { Button } from '@material-ui/core';
import styled from 'styled-components';

export const Wrapper = styled.div`
	display: grid;
	grid-template-areas:
		'time time'
		'startstop reset'
		'offset offset';
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr 1fr;
`;

export const StartButton = styled(Button)`
	grid-area: startstop;
`;

export const StopButton = styled(Button)`
	grid-area: startstop;
`;

export const ResetButton = styled(Button)`
	grid-area: reset;
`;

export const TimeWrapper = styled.div`
	grid-area: time;
	font-size: 2rem;
	text-align: center;
`;

export const Offset = styled(Button)`
	grid-area: offset;
`;
