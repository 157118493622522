import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { useFirebaseApp, useFirestore } from 'reactfire';
import { Roster } from '../../../models/roster';
import * as StyledRosterTable from './rosterTable.styles';

interface Props {
	rosters: Roster[];
}

const RosterTable: FC<Props> = ({ rosters }) => {
	const history = useHistory();
	const app = useFirebaseApp();
	const ref = useFirestore(app).collection('aftureldingStream').doc('rosters').collection('list');

	const newTeam = async () => {
		const team = await ref.add({
			teamName: 'New team',
			players: [],
		});
		history.push(`/team/${team.id}`);
	};

	const teamDetails = (teamId?: string) => {
		if (teamId) {
			history.push(`/team/${teamId}`);
		}
	};

	const deleteTeam = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, teamId?: string) => {
		if (teamId) {
			ref.doc(teamId).delete();
		}
		event.preventDefault();
		event.stopPropagation();
	};

	return (
		<StyledRosterTable.Wrapper>
			<Button color='primary' variant='contained' onClick={newTeam}>
				Nýtt lið
			</Button>
			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Liðsnafn</TableCell>
							<TableCell>Leikmannafjöldi</TableCell>
							<TableCell>Íþrótt</TableCell>
							<TableCell>Týpa</TableCell>
							<TableCell></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{rosters.length &&
							rosters.map((x, index) => (
								<StyledRosterTable.TeamRow
									key={x.teamName + index}
									hover={true}
									onClick={() => teamDetails(x.id)}
								>
									<TableCell>{x.teamName}</TableCell>
									<TableCell>{x.players ? x.players.length : 0}</TableCell>
									<TableCell>{x.sport}</TableCell>
									<TableCell>{x.type}</TableCell>
									<TableCell>
										<Button tabIndex={-1} color='secondary' onClick={(e) => deleteTeam(e, x.id)}>
											X
										</Button>
									</TableCell>
								</StyledRosterTable.TeamRow>
							))}
					</TableBody>
				</Table>
			</TableContainer>
		</StyledRosterTable.Wrapper>
	);
};

export default RosterTable;
