import React, { FC, Suspense, useState } from 'react';
import ClockControl from '../components/clockControl/clockControl';
import ScoreControl from '../components/ScoreControl/ScoreControl';
import { Team } from '../models/team';
import { useFirestore, useFirestoreDocData, useFirebaseApp, useFirestoreCollectionData } from 'reactfire';
import * as StyledControl from './control.styles';
import Header from '../components/header/header';
import { Roster } from '../models/roster';
import RosterPreview from '../components/rosters/rosterPreview/rosterPreview';
import { Button } from '@material-ui/core';

const Control: FC = () => {
	const [leftRoster, setLeftRoster] = useState<Roster | null>(null);
	const [rightRoster, setRightRoster] = useState<Roster | null>(null);
	const app = useFirebaseApp();

	const rosterRef = useFirestore(app).collection('aftureldingStream').doc('rosters').collection('list');
	const allRosters: Roster[] = useFirestoreCollectionData(rosterRef, { idField: 'id' });

	const liveRosterRef = useFirestore(app).collection('aftureldingStream').doc('liveRoster');
	const liveRoster: Roster | undefined = useFirestoreDocData(liveRosterRef);

	const teamLeftRef = useFirestore(app).collection('aftureldingStream').doc('teamLeft');
	const teamRightRef = useFirestore(app).collection('aftureldingStream').doc('teamRight');
	const teamLeft: Team = useFirestoreDocData(teamLeftRef);
	const teamRight: Team = useFirestoreDocData(teamRightRef);
	if (teamLeft.roster && !leftRoster && allRosters.find((x) => x.id === teamLeft.roster))
		setLeftRoster(allRosters.find((x) => x.id === teamLeft.roster) || null);
	if (teamRight.roster && !rightRoster && allRosters.find((x) => x.id === teamRight.roster))
		setRightRoster(allRosters.find((x) => x.id === teamRight.roster) || null);

	const editTeam = (team: Team, field: string, value: any) => {
		if (team.side === 'left') {
			teamLeftRef.update({
				[field]: value,
			});
		} else {
			teamRightRef.update({
				[field]: value,
			});
		}
	};

	const setTeamRoster = (team: Team, roster: Roster) => {
		if (team.side === 'left') {
			teamLeftRef.update({
				name: roster.teamName,
				roster: roster.id || null,
			});
			setLeftRoster(roster);
		} else {
			teamRightRef.update({
				name: roster.teamName,
				roster: roster.id || null,
			});
			setRightRoster(roster);
		}
	};

	const setLiveRoster = (roster: Roster) => {
		liveRosterRef.set(roster);
	};

	const hideLiveRoster = () => {
		liveRosterRef.delete();
	};

	return (
		<div className='page Control'>
			<Header
				navItems={[
					{ text: 'Lið', route: '/teams' },
					{ text: 'LIVE', route: '/stream' },
				]}
			/>
			<StyledControl.Wrapper>
				<StyledControl.ScoreControl>
					<Suspense fallback={'loading...'}>
						<ScoreControl
							rosters={allRosters}
							team={teamLeft}
							editTeam={editTeam}
							setRoster={setTeamRoster}
						/>
					</Suspense>
					<ClockControl />
					<Suspense fallback={'loading...'}>
						<ScoreControl
							rosters={allRosters}
							team={teamRight}
							editTeam={editTeam}
							setRoster={setTeamRoster}
						/>
					</Suspense>
				</StyledControl.ScoreControl>
				<StyledControl.ActiveRosters>
					<StyledControl.RosterSection>
						{leftRoster !== null && (
							<>
								{liveRoster?.teamName === leftRoster.teamName ? (
									<Button variant='contained' color='secondary' onClick={() => hideLiveRoster()}>
										Fela leikmannaskrá
									</Button>
								) : (
									<Button
										variant='contained'
										color='primary'
										onClick={() => setLiveRoster(leftRoster)}
									>
										Sýna leikmannaskrá LIVE
									</Button>
								)}
								<RosterPreview roster={leftRoster} />
							</>
						)}
					</StyledControl.RosterSection>
					<StyledControl.RosterSection>
						{rightRoster !== null && (
							<>
								{liveRoster?.teamName === rightRoster.teamName ? (
									<Button variant='contained' color='secondary' onClick={() => hideLiveRoster()}>
										Fela leikmannaskrá
									</Button>
								) : (
									<Button
										variant='contained'
										color='primary'
										onClick={() => setLiveRoster(rightRoster)}
									>
										Sýna leikmannaskrá LIVE
									</Button>
								)}
								<RosterPreview roster={rightRoster} />
							</>
						)}
					</StyledControl.RosterSection>
				</StyledControl.ActiveRosters>
			</StyledControl.Wrapper>
		</div>
	);
};

export default Control;
