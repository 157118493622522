import React, { FC, useState } from 'react';
import * as StyledScore from './ScoreControl.styles';
import { TextField, MenuItem } from '@material-ui/core';
import { Team } from '../../models/team';
import { Roster } from '../../models/roster';

interface Props {
	rosters: Roster[];
	team: Team;
	editTeam: (team: Team, field: string, value: any) => void;
	setRoster: (team: Team, roster: Roster) => void;
}

const ScoreControl: FC<Props> = ({ team, editTeam, rosters, setRoster }) => {
	const [menuOpen, setMenuOpen] = useState<null | HTMLElement>(null);

	const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
		setMenuOpen(event.currentTarget);
	};

	const handleMenuClose = () => {
		setMenuOpen(null);
	};

	const setTeamRoster = (roster: Roster) => {
		setRoster(team, roster);
		handleMenuClose();
	};

	return (
		<StyledScore.Wrapper>
			<StyledScore.Name noValidate autoComplete='off'>
				{team && team.name !== null && (
					<TextField
						fullWidth={true}
						value={team.name}
						label='Nafn liðs'
						variant='outlined'
						onChange={(e) => editTeam(team, 'name', e.target.value)}
					/>
				)}
			</StyledScore.Name>
			<StyledScore.RosterMenuButton onClick={handleMenuOpen}>↓</StyledScore.RosterMenuButton>
			<StyledScore.RosterMenu anchorEl={menuOpen} keepMounted open={Boolean(menuOpen)} onClose={handleMenuClose}>
				{rosters.map((x) => (
					<MenuItem key={x.id} onClick={() => setTeamRoster(x)}>
						{x.teamName}
					</MenuItem>
				))}
			</StyledScore.RosterMenu>
			<StyledScore.Score>{team.score}</StyledScore.Score>
			<StyledScore.ScorePlus
				color='primary'
				variant='contained'
				onClick={() => editTeam(team, 'score', team.score + 1)}
			>
				+
			</StyledScore.ScorePlus>
			<StyledScore.ScoreMinus
				color='secondary'
				variant='contained'
				onClick={() => editTeam(team, 'score', team.score !== 0 ? team.score - 1 : 0)}
			>
				-
			</StyledScore.ScoreMinus>
		</StyledScore.Wrapper>
	);
};

export default ScoreControl;
