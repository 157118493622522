import { TableRow, TableCell } from '@material-ui/core';
import React, { FC } from 'react';
import { Player } from '../../../models/roster';

interface Props {
	player: Player;
}

const PlayerDisplay: FC<Props> = ({ player }) => {
	if (!player.visible) return null;
	return (
		<TableRow>
			<TableCell>{player.number}</TableCell>
			<TableCell>{player.name}</TableCell>
		</TableRow>
	);
};

export default PlayerDisplay;
