import React, { Suspense } from 'react';
import './App.css';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import { FirebaseAppProvider } from 'reactfire';
import { firebaseConfig } from './config/firebase';
import Control from './pages/control';
import Stream from './pages/stream';
import Rosters from './pages/rosters';
import RosterDetails from './pages/rosterDetails';

function App() {
	return (
		<FirebaseAppProvider firebaseConfig={firebaseConfig}>
			<BrowserRouter>
				<Switch>
					<Suspense fallback={<div>Loading...</div>}>
						<Route path='/stream'>
							<Stream />
						</Route>
						<Route path='/control'>
							<Control />
						</Route>
						<Route path='/teams'>
							<Rosters />
						</Route>
						<Route path='/team/:id'>
							<RosterDetails />
						</Route>
						<Route exact path='/'>
							<Control />
						</Route>
					</Suspense>
				</Switch>
			</BrowserRouter>
		</FirebaseAppProvider>
	);
}

export default App;
