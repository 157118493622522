import { Team } from '../models/team';
import React, { FC, useEffect, useState } from 'react';
import { useFirestore, useFirestoreDocData, useFirebaseApp } from 'reactfire';
import { StreamWrapper, DataWrapper, TeamLeftName, TeamRightName, Score, Time } from './stream.styles';
import { Clock } from '../models/clock';
import { convertTimediffToClock } from '../helpers/time';
import { Roster } from '../models/roster';
import LiveRoster from '../components/rosters/liveRoster/liveRoster';

const Stream: FC = () => {
	const app = useFirebaseApp();
	const teamLeftRef = useFirestore(app).collection('aftureldingStream').doc('teamLeft');
	const teamRightRef = useFirestore(app).collection('aftureldingStream').doc('teamRight');
	const clockRef = useFirestore(app).collection('aftureldingStream').doc('clock');
	const liveRosterRef = useFirestore(app).collection('aftureldingStream').doc('liveRoster');
	const liveRoster: Roster = useFirestoreDocData(liveRosterRef);
	const teamLeft: Team = useFirestoreDocData(teamLeftRef, {
		startWithValue: {
			name: '',
			score: 0,
			side: 'left',
		},
	});
	const teamRight: Team = useFirestoreDocData(teamRightRef, {
		startWithValue: {
			name: '',
			score: 0,
			side: 'right',
		},
	});
	const clock: Clock = useFirestoreDocData(clockRef);
	const [time, setTime] = useState(convertTimediffToClock(clock));
	const [cb, setTimeoutCb] = useState<number | null>(null);

	useEffect(() => {
		if (clock) {
			updateClockDisplay();
		}
		if (clock.started) {
			setTimer();
		} else if (!clock.started && cb) {
			clearTimeout(cb);
			setTimeoutCb(null);
		}
		// eslint-disable-next-line
	}, [clock]);

	const setTimer = () => {
		if (clock.started && !cb) {
			setTimeoutCb(
				setTimeout(() => {
					updateClockDisplay();
					setTimer();
				}, 1000)
			);
		} else {
			console.log('unknown state');
			console.log(clock);
		}
	};

	const updateClockDisplay = () => setTime(convertTimediffToClock(clock));

	return (
		<StreamWrapper>
			<DataWrapper>
				<TeamLeftName>{teamLeft.name}</TeamLeftName>
				<TeamRightName>{teamRight.name}</TeamRightName>
				<Score>
					{teamLeft.score}:{teamRight.score}
				</Score>
				<Time>{time}</Time>
			</DataWrapper>
			<LiveRoster roster={liveRoster} />
		</StreamWrapper>
	);
};

export default Stream;
