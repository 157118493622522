import React, { FC } from 'react';
import { useFirebaseApp, useFirestore, useFirestoreDocData } from 'reactfire';
import * as StyledRosters from './rosters.styles';
import RosterEdit from '../components/rosters/rosterEdit/rosterEdit';
import { useHistory, useParams } from 'react-router';
import { Roster } from '../models/roster';
import Header from '../components/header/header';

const RosterDetails: FC = () => {
	const history = useHistory();
	const { id } = useParams<{ id: string }>();
	const app = useFirebaseApp();
	const ref = useFirestore(app).collection('aftureldingStream').doc('rosters').collection('list').doc(id);
	const team: Roster = useFirestoreDocData(ref);

	const saveRoster = (roster?: Roster) => {
		if (roster) {
			console.log(roster);
			ref.set(roster);
		}
		history.goBack();
	};

	return (
		<>
			<Header
				navItems={[
					{ text: 'Lið', route: '/teams' },
					{ text: 'Stjórnborð', route: '/control' },
					{ text: 'LIVE', route: '/stream' },
				]}
			/>
			<StyledRosters.Wrapper>
				<StyledRosters.PageTitle>Leikmannaskrár</StyledRosters.PageTitle>
				<RosterEdit team={team} onSave={saveRoster} />
			</StyledRosters.Wrapper>
		</>
	);
};

export default RosterDetails;
