import { Button, Menu } from '@material-ui/core';
import styled from 'styled-components';

export const Wrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-template-rows: 4rem 1fr 3rem;

	grid-template-areas:
		'name name name rosters'
		'. score score .'
		'. plus minus .';
`;

export const Name = styled.form`
	grid-area: name;
`;

export const Score = styled.div`
	grid-area: score;
	font-size: 3em;
	padding: 10px 1em;
	border: 1px solid #ccc;
	margin-bottom: 5px;
`;

export const ScorePlus = styled(Button)`
	grid-area: plus;
`;

export const ScoreMinus = styled(Button)`
	grid-area: minus;
`;

export const RosterMenuButton = styled(Button)`
	grid-area: rosters;
`;

export const RosterMenu = styled(Menu)`
	grid-area: rosters;
`;
