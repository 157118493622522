import styled from 'styled-components';

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const Background = styled.div`
	height: 800px;
	width: 1217px;
	margin-bottom: 4rem;
	background-image: url('/assets/teamPreview.png');
	display: grid;
	grid-gap: 20px;
	grid-template-rows: 5rem auto;
	grid-template-columns: 5rem auto auto 5rem;
	grid-template-areas:
		'name name name name'
		'. players players .';
`;

export const Team = styled.div`
	grid-area: name;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 4rem;
	color: white;
`;

export const Players = styled.div`
	grid-area: players;
	max-height: calc(800px - 5rem - 40px);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
`;

export const PlayerEntry = styled.div`
	display: flex;
	align-items: center;
	width: 45%;
`;

export const PlayerNumber = styled.div`
	height: 3rem;
	width: 3rem;
	margin: 0.2rem 0rem;

	display: flex;
	justify-content: center;
	align-items: center;

	font-size: 2rem;
	color: white;
	background-color: #942125;

	border-top-left-radius: 0.5rem;
	border-bottom-left-radius: 0.5rem;
`;

export const PlayerName = styled.div`
	display: flex;
	align-items: center;
	padding-left: 1rem;
	font-size: 1.5rem;
	color: white;
	width: 100%;
	height: 3rem;

	border-top-right-radius: 0.5rem;
	border-bottom-right-radius: 0.5rem;
	background: rgb(153, 34, 38);
	background: linear-gradient(90deg, rgba(153, 34, 38, 0.7) 0%, rgba(167, 44, 49, 0.7) 100%);
`;

export const StaffEntry = styled(PlayerEntry)`
	flex-direction: column;
	align-items: flex-start;

	margin-top: 1rem;
`;

export const StaffPosition = styled.div`
	padding: 0.5rem 1rem;
	background-color: #942125;
	color: white;
	min-width: 40%;
	border-top-left-radius: 0.5rem;
	border-top-right-radius: 0.5rem;
`;

export const StaffName = styled(PlayerName)`
	border-bottom-left-radius: 0.5rem;
`;
