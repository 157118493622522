import React, { FC } from 'react';
import { useFirebaseApp, useFirestore, useFirestoreCollectionData } from 'reactfire';
import * as StyledRosters from './rosters.styles';
import RosterTable from '../components/rosters/rosterTable/rosterTable';
import { Roster } from '../models/roster';
import Header from '../components/header/header';

const Rosters: FC = () => {
	const app = useFirebaseApp();
	const ref = useFirestore(app).collection('aftureldingStream').doc('rosters').collection('list');
	const rosters: Roster[] = useFirestoreCollectionData(ref, { idField: 'id' });

	return (
		<>
			<Header
				navItems={[
					{ text: 'Stjórnborð', route: '/control' },
					{ text: 'LIVE', route: '/stream' },
				]}
			/>
			<StyledRosters.Wrapper>
				<StyledRosters.PageTitle>Leikmannaskrár</StyledRosters.PageTitle>
				<RosterTable rosters={rosters} />
			</StyledRosters.Wrapper>
		</>
	);
};

export default Rosters;
