import { Fade } from '@material-ui/core';
import React, { FC } from 'react';
import { Roster } from '../../../models/roster';
import * as StyledLiveRoster from './liveRoster.styles';

interface Props {
	roster?: Roster;
}

const LiveRoster: FC<Props> = ({ roster }) => {
	return (
		<StyledLiveRoster.Wrapper>
			<Fade in={roster?.teamName !== undefined} timeout={500}>
				<StyledLiveRoster.Background>
					<StyledLiveRoster.Team>{roster?.teamName || ''}</StyledLiveRoster.Team>
					<StyledLiveRoster.Players>
						{roster?.players
							?.sort((a, b) => a.number - b.number)
							.map((x, index) => {
								if (!x.visible) return null;
								return (
									<StyledLiveRoster.PlayerEntry key={index}>
										<StyledLiveRoster.PlayerNumber>{x.number}</StyledLiveRoster.PlayerNumber>
										<StyledLiveRoster.PlayerName>{x.name}</StyledLiveRoster.PlayerName>
									</StyledLiveRoster.PlayerEntry>
								);
							}) || null}
						{roster?.staff?.map((x, idx) => (
							<StyledLiveRoster.StaffEntry key={idx}>
								<StyledLiveRoster.StaffPosition>{x.position}</StyledLiveRoster.StaffPosition>
								<StyledLiveRoster.StaffName>{x.name}</StyledLiveRoster.StaffName>
							</StyledLiveRoster.StaffEntry>
						))}
					</StyledLiveRoster.Players>
				</StyledLiveRoster.Background>
			</Fade>
		</StyledLiveRoster.Wrapper>
	);
};

export default LiveRoster;
