import * as StyledClockControl from './clockControl.styles';
import React, { FC, Suspense, useEffect, useState } from 'react';
import { Clock } from '../../models/clock';
import { useFirestoreDocData, useFirebaseApp, useFirestore } from 'reactfire';
import { convertTimediffToClock, getValues, subtractValuesFromDate } from '../../helpers/time';
import { Button, Dialog, DialogTitle, TextField } from '@material-ui/core';

interface ManualSetTime {
	minutes: number;
	seconds: number;
}

const ClockControl: FC = () => {
	const app = useFirebaseApp();
	const ref = useFirestore(app).collection('aftureldingStream').doc('clock');
	const clock: Clock = useFirestoreDocData(ref);
	const [time, setTime] = useState(convertTimediffToClock(clock));
	const [cb, setTimeoutCb] = useState<number | null>(null);
	const [manualSetValues, setManualSetValue] = useState<ManualSetTime>({ minutes: 0, seconds: 0 });
	const [dialogOpen, setDialogOpen] = useState(false);

	useEffect(() => {
		if (clock) {
			updateClockDisplay();
		}
		if (clock.started) {
			setTimer();
		} else if (!clock.started && cb) {
			clearTimeout(cb);
			setTimeoutCb(null);
		}
		// eslint-disable-next-line
	}, [clock]);

	const start = () => {
		const now = new Date().valueOf();
		let startValue = now;
		if (clock.stopValue) {
			startValue = now - (clock.stopValue - clock.startValue);
		}
		ref.update({
			startValue: startValue,
			stopValue: null,
			started: true,
		});
	};
	const pause = () => {
		stopTimer();
		ref.update({
			started: false,
			stopValue: new Date().valueOf(),
		});
	};
	const reset = async () => {
		stopTimer();
		await ref.update({
			started: false,
			startValue: null,
			stopValue: null,
		});
	};

	const stopTimer = () => {
		if (cb) {
			clearTimeout(cb);
			setTimeoutCb(null);
		}
	};

	const setTimer = () => {
		if (clock.started && !cb) {
			setTimeoutCb(
				setTimeout(() => {
					updateClockDisplay();
					setTimer();
				}, 1000)
			);
		} else {
			console.log('unknown state');
			console.log(clock);
		}
	};

	const openManualTime = () => {
		setManualSetValue(getValues(clock));
		setDialogOpen(true);
	};

	const setManualTime = () => {
		const newStart = subtractValuesFromDate(manualSetValues.minutes, manualSetValues.seconds);
		ref.update({
			startValue: newStart,
			stopValue: new Date().valueOf(),
		}).then(() => {
			setDialogOpen(false);
		});
	};

	const updateClockDisplay = () => setTime(convertTimediffToClock(clock));

	return (
		<>
			<Suspense fallback='Loading clock...'>
				<StyledClockControl.Wrapper>
					<StyledClockControl.TimeWrapper>{time}</StyledClockControl.TimeWrapper>
					{clock.started ? (
						<StyledClockControl.StopButton color='primary' variant='contained' onClick={() => pause()}>
							Pause
						</StyledClockControl.StopButton>
					) : (
						<StyledClockControl.StartButton color='primary' variant='contained' onClick={() => start()}>
							Start
						</StyledClockControl.StartButton>
					)}
					<StyledClockControl.ResetButton color='secondary' variant='contained' onClick={() => reset()}>
						Stop & Reset
					</StyledClockControl.ResetButton>
					<StyledClockControl.Offset
						disabled={clock.started}
						color='default'
						variant='contained'
						onClick={() => openManualTime()}
					>
						Stilla tíma {clock.started ? '(ekki hægt meðan klukka er í gangi)' : ''}
					</StyledClockControl.Offset>
				</StyledClockControl.Wrapper>
			</Suspense>
			<Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
				<DialogTitle>Stilla tíma</DialogTitle>
				<form noValidate autoComplete='off'>
					<TextField
						value={manualSetValues.minutes}
						label='Mínúta'
						variant='outlined'
						onChange={(e) =>
							setManualSetValue({ minutes: parseInt(e.target.value), seconds: manualSetValues.seconds })
						}
					/>
					<TextField
						value={manualSetValues.seconds}
						label='Sekúnda'
						variant='outlined'
						onChange={(e) =>
							setManualSetValue({ seconds: parseInt(e.target.value), minutes: manualSetValues.minutes })
						}
					/>
				</form>
				<Button color='primary' onClick={() => setManualTime()}>
					Staðfesta
				</Button>
				<Button color='secondary' onClick={() => setDialogOpen(false)}>
					Loka
				</Button>
			</Dialog>
		</>
	);
};

export default ClockControl;
