import {
	Button,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
} from '@material-ui/core';
import React, { FC, useState } from 'react';
import { Player, Roster, Staff } from '../../../models/roster';
import RosterEditPlayerEntry from './playerEntry/playerEntry';
import RosterEditStaffEntry from './staffEntry/staffEntry';
import * as StyledRosterDetails from './rosterEdit.styles';

interface Props {
	team: Roster;
	onSave: (roster?: Roster) => void;
}

const RosterEdit: FC<Props> = ({ team, onSave }) => {
	const [name, setName] = useState<string>(team && team.teamName ? team.teamName : '');
	const [players, setPlayers] = useState<Player[]>(team.players ? team.players : []);
	const [staff, setStaff] = useState<Staff[]>(team.staff ? team.staff : []);
	const [sport, setSport] = useState<string>(team && team.sport ? team.sport : '');
	const [type, setType] = useState<string>(team && team.type ? team.type : '');

	const addPlayer = () => {
		const player: Player = {
			name: '',
			number: 1,
		};
		const updatedPlayers = players;
		updatedPlayers.push(player);
		setPlayers(updatedPlayers.slice());
	};
	const deletePlayer = (index: number) => {
		const updatedPlayers = players;
		updatedPlayers.splice(index, 1);
		setPlayers(updatedPlayers.slice());
	};
	const addStaff = () => {
		const newStaff: Staff = {
			name: '',
			position: '',
		};
		const updatedStaff = staff;
		updatedStaff.push(newStaff);
		setStaff(updatedStaff.slice());
	};
	const deleteStaff = (index: number) => {
		const updatedStaff = staff;
		updatedStaff.splice(index, 1);
		setStaff(updatedStaff.slice());
	};
	const updateTeamName = (value: string) => {
		if (!value) value = '';
		setName(value);
	};
	const updateSport = (value: string) => {
		if (!value) value = '';
		setSport(value);
	};
	const updateType = (value: string) => {
		if (!value) value = '';
		setType(value);
	};

	const updatePlayer = (playerIndex: number, name: string, number: number, visible: boolean) => {
		const updatedPlayers = players;
		players[playerIndex] = {
			name,
			number,
			visible,
		};
		setPlayers(updatedPlayers);
	};
	const updateStaff = (staffIndex: number, name: string, position: string) => {
		const updatedStaff = staff;
		staff[staffIndex] = {
			name,
			position,
		};
		setStaff(updatedStaff);
	};
	const cancel = () => {
		onSave();
	};
	const save = () => {
		onSave({ teamName: name, players, sport, type, staff });
	};

	return (
		<StyledRosterDetails.Wrapper>
			<StyledRosterDetails.Title>Breyta liði</StyledRosterDetails.Title>
			<StyledRosterDetails.TeamInputs>
				<TextField
					label='Liðsnafn'
					value={name}
					onChange={(event) => updateTeamName(event.target?.value || '')}
				/>
				<TextField label='Íþrótt' value={sport} onChange={(event) => updateSport(event.target?.value || '')} />
				<TextField label='Týpa' value={type} onChange={(event) => updateType(event.target?.value || '')} />
			</StyledRosterDetails.TeamInputs>
			<StyledRosterDetails.Players>
				<StyledRosterDetails.NewPlayerButton onClick={() => addPlayer()} variant='contained'>
					Bæta við leikmanni
				</StyledRosterDetails.NewPlayerButton>
				<TableContainer component={Paper}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Nafn</TableCell>
								<TableCell>Númer</TableCell>
								<TableCell>Sýndur</TableCell>
								<TableCell></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{players.map((x, index) => (
								<RosterEditPlayerEntry
									key={x.name + index}
									player={x}
									index={index}
									onDelete={deletePlayer}
									onSave={updatePlayer}
								/>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</StyledRosterDetails.Players>
			<StyledRosterDetails.Staff>
				<StyledRosterDetails.NewPlayerButton onClick={() => addStaff()} variant='contained'>
					Bæta við starfsmanni
				</StyledRosterDetails.NewPlayerButton>
				<TableContainer component={Paper}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Nafn</TableCell>
								<TableCell>Staða</TableCell>
								<TableCell></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{staff.map((x, index) => (
								<RosterEditStaffEntry
									key={x.name + index}
									staff={x}
									index={index}
									onDelete={deleteStaff}
									onSave={updateStaff}
								/>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</StyledRosterDetails.Staff>
			<StyledRosterDetails.ConfirmButtons>
				<Button variant='contained' color='primary' onClick={() => save()}>
					Vista
				</Button>
				<Button variant='contained' color='secondary' onClick={() => cancel()}>
					Hætta við
				</Button>
			</StyledRosterDetails.ConfirmButtons>
		</StyledRosterDetails.Wrapper>
	);
};

export default RosterEdit;
