import styled from 'styled-components';

export const StreamWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 1920px;
	height: 1080px;
	background-image: url('/assets/overlay.png');
`;

export const DataWrapper = styled.div`
	position: absolute;
	bottom: 28px;
	width: 530px;
	left: 700px;
	height: 120px;
	display: grid;
	grid-template-columns: 100px 330px 100px;
	grid-template-rows: 43px 77px;

	grid-template-areas:
		'. time .'
		'team-left score team-right';
`;

export const TeamLeftName = styled.div`
	grid-area: team-left;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 20px;
	font-size: 20px;
	font-weight: bold;
`;

export const Score = styled.div`
	grid-area: score;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	font-weight: bold;
	font-size: 65px;
	padding-bottom: 5px;
`;

export const Time = styled.div`
	grid-area: time;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 20px;
	font-weight: bold;
`;

export const TeamRightName = styled.div`
	grid-area: team-right;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 20px;
	font-size: 20px;
	font-weight: bold;
`;
