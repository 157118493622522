import { TableRow, TableCell, TextField, Button, Switch } from '@material-ui/core';
import React, { FC, useState } from 'react';
import { Player } from '../../../../models/roster';

interface Props {
	player: Player;
	index: number;
	onDelete: (index: number) => void;
	onSave: (index: number, name: string, number: number, visible: boolean) => void;
}

const RosterEditPlayerEntry: FC<Props> = ({ player, index, onDelete, onSave }) => {
	const [name, setName] = useState(player.name);
	const [number, setNumber] = useState(player.number);
	const [visible, setVisible] = useState(player.visible ? player.visible : false);

	const updateName = (value: string) => {
		if (typeof value !== 'string') return;
		setName(value);
		onSave(index, value, number, visible);
	};
	const updateNumber = (value: number) => {
		setNumber(value);
		if (!isNaN(value)) {
			onSave(index, name, value, visible);
		}
	};
	const updateVisible = (value: boolean) => {
		setVisible(value);
		onSave(index, name, number, value);
	};

	return (
		<TableRow key={player.name + index}>
			<TableCell tabIndex={-1}>
				<TextField
					tabIndex={1}
					label='Nafn'
					value={name}
					type='text'
					onChange={(event) => updateName(event.target?.value || '')}
				/>
			</TableCell>
			<TableCell tabIndex={-1}>
				<TextField
					tabIndex={2}
					label='Númer'
					value={number}
					type='number'
					onChange={(event) => updateNumber(parseInt(event.target.value))}
				/>
			</TableCell>
			<TableCell>
				<Switch
					checked={visible}
					onChange={(event) => updateVisible(event.target.checked)}
					name={player.name + player.number + 'visibleSwitch'}
				/>
			</TableCell>
			<TableCell>
				<Button tabIndex={-1} color='secondary' onClick={() => onDelete(index)}>
					X
				</Button>
			</TableCell>
		</TableRow>
	);
};

export default RosterEditPlayerEntry;
