import { Clock } from '../models/clock';

export const convertCounterToClock = (counter: number, startMinute: number) => {
	const minutes = startMinute + Math.floor(counter / 60);
	const seconds = counter % 60;
	return `${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
};

export const getValues = (clock: Clock): { minutes: number; seconds: number } => {
	if (clock.startValue === null) {
		return { minutes: 0, seconds: 0 };
	}
	const now = new Date().valueOf();
	let secondsSinceStart = Math.floor((now - clock.startValue) / 1000);
	if (clock.stopValue) {
		secondsSinceStart = Math.floor((clock.stopValue - clock.startValue) / 1000);
	}
	const minutes = Math.floor(secondsSinceStart / 60);
	const seconds = secondsSinceStart % 60;
	return { minutes, seconds };
};

export const subtractValuesFromDate = (minutes: number, seconds: number) => {
	const now = new Date().valueOf();
	const minutesToMs = minutes * 1000 * 60;
	const secondsToMs = seconds * 1000;
	return now - minutesToMs - secondsToMs;
};

export const convertTimediffToClock = (clock: Clock) => {
	if (clock.startValue === null || typeof clock.startValue !== 'number') {
		return '00:00';
	}
	let now = new Date().valueOf();
	let secondsSinceStart = Math.floor((now - clock.startValue) / 1000);
	if (clock.stopValue) {
		secondsSinceStart = Math.floor((clock.stopValue - clock.startValue) / 1000);
	}
	const minutes = Math.floor(secondsSinceStart / 60);
	const seconds = secondsSinceStart % 60;
	return renderTimer(minutes, seconds);
};

const renderTimer = (minutes: number, seconds: number) => {
	return `${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
};
