import { Button } from '@material-ui/core';
import styled from 'styled-components';

export const Wrapper = styled.div`
	padding: 2rem;
	display: grid;
	grid-gap: 2rem;
	grid-template-areas:
		'title buttons'
		'team team'
		'players staff';
	grid-template-rows: 1fr auto;
	grid-template-columns: 1fr 1fr;
`;

export const ConfirmButtons = styled.div`
	grid-area: buttons;
`;

export const Title = styled.h1`
	grid-area: title;
`;

export const NewPlayerButton = styled(Button)`
	margin-bottom: 1rem;
`;

export const TeamInputs = styled.div`
	grid-area: team;
	display: flex;
	justify-content: space-between;
	margin-bottom: 2rem;
`;

export const Players = styled.div`
	grid-area: players;
`;

export const Staff = styled.div`
	grid-area: staff;
`;
