import styled from 'styled-components';

export const Wrapper = styled.main`
	display: flex;
	flex-direction: column;
`;

export const ScoreControl = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1rem;
`;

export const ActiveRosters = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const RosterSection = styled.div`
	display: flex;
	flex-direction: column;
	flex-basis: 50%;
	margin: 1rem;
`;
