import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import React, { FC } from 'react';
import { Roster } from '../../../models/roster';
import PlayerDisplay from '../playerDisplay/playerDisplay';

interface Props {
	roster: Roster;
}

const RosterPreview: FC<Props> = ({ roster }) => {
	return (
		<TableContainer component={Paper}>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Númer</TableCell>
						<TableCell>Nafn</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{roster.players.map((x, index) => (
						<PlayerDisplay player={x} key={x.name + index} />
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default RosterPreview;
