import { AppBar, Toolbar, Button } from '@material-ui/core';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import * as StyledHeader from './header.styles';

interface Props {
	navItems: Array<{ text: string; route: string }>;
}

const Header: FC<Props> = ({ navItems }) => {
	const navigation = useHistory();

	const navigate = (to: string) => {
		navigation.push(to);
	};

	return (
		<AppBar position='static'>
			<Toolbar>
				<StyledHeader.Title variant='h6'>Streymi</StyledHeader.Title>
				{navItems.map((x, index) => (
					<Button key={index} color='inherit' onClick={() => navigate(x.route)}>
						{x.text}
					</Button>
				))}
			</Toolbar>
		</AppBar>
	);
};

export default Header;
