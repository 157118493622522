export const firebaseConfig = {
	apiKey: 'AIzaSyAygNKvXzuxlNg8Gj2ez-i_6Wpr61TFY9M',
	authDomain: 'project-databases-66438.firebaseapp.com',
	databaseURL: 'https://project-databases-66438.firebaseio.com',
	projectId: 'project-databases-66438',
	storageBucket: 'project-databases-66438.appspot.com',
	messagingSenderId: '362570862488',
	appId: '1:362570862488:web:bf3092710d23dabc2fe5ed',
	measurementId: 'G-NJJRTB9SLT',
};
