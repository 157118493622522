import { TableRow, TableCell, TextField, Button } from '@material-ui/core';
import React, { FC, useState } from 'react';
import { Staff } from '../../../../models/roster';

interface Props {
	staff: Staff;
	index: number;
	onDelete: (index: number) => void;
	onSave: (index: number, name: string, position: string) => void;
}

const RosterEditStaffEntry: FC<Props> = ({ staff, index, onDelete, onSave }) => {
	const [name, setName] = useState(staff.name);
	const [position, setPosition] = useState(staff.position);

	const updateName = (value: string) => {
		if (typeof value !== 'string') return;
		setName(value);
		onSave(index, value, position);
	};
	const updatePosition = (value: string) => {
		if (typeof value !== 'string') return;
		setPosition(value);
		onSave(index, name, value);
	};

	return (
		<TableRow key={staff.name + index}>
			<TableCell tabIndex={-1}>
				<TextField
					tabIndex={1}
					label='Nafn'
					value={name}
					type='text'
					onChange={(event) => updateName(event.target?.value || '')}
				/>
			</TableCell>
			<TableCell tabIndex={-1}>
				<TextField
					tabIndex={2}
					label='Staða'
					value={position}
					type='text'
					onChange={(event) => updatePosition(event.target?.value || '')}
				/>
			</TableCell>
			<TableCell>
				<Button tabIndex={-1} color='secondary' onClick={() => onDelete(index)}>
					X
				</Button>
			</TableCell>
		</TableRow>
	);
};

export default RosterEditStaffEntry;
